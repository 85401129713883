const useStyles = () => {
   const classesUseStyles = {
      container: {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         backgroundColor: 'f2f2f2#',
         height: 1200,
      },
      sidebar: {
         height: '100%',
         flexGrow: 1,
      },
      customTable: {
         width: '100%',
         height: '100%',
         flexGrow: 1
      },
      filtersContainer: {
         minHeight: '100%',
         width: 268,
         backgroundColor: 'rgba(188, 136, 255, 0.1)',
         flexGrow: 1,
         overflowY: 'auto', // Scroll vertical
         maxHeight: '1000px', // Limita
      },
      inputFilter: {
         width: '100%',
         backgroundColor: '#fff',
         color: '#000',
      },
      selectContainer: {
         height: 35,
         color: '#000',
      },
      searchInput: {
         display: 'flex',
         alignItems: 'center',
         width: '100%' ,
         border: '1px solid #ddd',
         height: '35px',
         marginBottom: '4px',}
   }
   return {classesUseStyles}
}
export default useStyles;

import React from 'react';
import Chip from '@mui/material/Chip';

const CustomChip = ({label, onDelete, onClick, index, sx, color}: any) => {

   return (
      <Chip
         label={label}
         onClick={onClick}
         onDelete={onDelete && (() => onDelete(index))}
         size="small"
         color={color}
         sx={sx}
      />
   );
}
export default CustomChip;

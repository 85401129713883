import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "./api";

export const apiSlice = createApi({
   reducerPath: 'api',
   baseQuery: baseQueryWithReauth,
   tagTypes: ['Interview', 'Candidate'],
   endpoints(builder) {
      return {
         auth: builder.mutation({
            query: (body) => ({
               url: '/auth',
               method: 'POST',
               body: body
            }),
         }),
         fetchCandidates: builder.query({
            query: (params) => ({
               url: `/candidates`,
               params
            }),
         }),
         fetchSkills: builder.query({
            query: ({skill}) => ({
               url: `/skills/${skill}`,
            }),
         })
      }
   }
})

export const {
   useAuthMutation,
   useFetchCandidatesQuery,
   useFetchSkillsQuery
} = apiSlice;

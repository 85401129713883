import React, {useEffect} from "react";
import useCandidatesViewModel from "../view-models/useCandidatesViewModel";
import useBoardViewModel from "../view-models/useBoardViewModel";
import {RANGE_SALARY, RANGE_YEARS_EXPERIENCE} from "../data/constant/constants";

const useBoardController = () => {
   const [rangeYears, setRangeYears] = React.useState<number[]>([0, 15]);
   const [rangeSalary, setRangeSalary] = React.useState<number[]>([0, 150]);

   const {
      //Fetch all candidates
      candidates,
      //Fetch skills
      skills,
      isFetchingSkills,
      setSkill,
      //Fn
      setCandidates
   } = useCandidatesViewModel();

   const {
      filterData,
      isFetchingFilterData,
      isSuccessFilterData,
      isErrorFilterData,
      filters,
      setUpdateFilter,
      setSkill: setSelectedSkill,
      removeSkill,
      resetFilters,
      page,
      setPage
   } = useBoardViewModel();

   useEffect(() => {
      setPage(1);
   }, []);

   useEffect(() => {
      if (isSuccessFilterData) {
         setCandidates(filterData)
      }
      if (isErrorFilterData) {
         setCandidates([]);
      }
   }, [isSuccessFilterData, isErrorFilterData, filterData]);

   const updateFilter = (name: any, event: any) => {
      if (page > 1) setPage(1);
      if (name === "yearsOfExperience") {
         // @ts-ignore
         if (event?.target.value[1] === RANGE_YEARS_EXPERIENCE) {
            setUpdateFilter({
               key: name,
               value: {
                  min: event?.target.value[0],
                  max: ""
               }
            })
            setRangeYears(event?.target.value);
            return;
         }
         setUpdateFilter({
            key: name,
            value: {
               min: event?.target.value[0],
               max: event?.target.value[1]
            }
         })
         setRangeYears(event?.target.value);
      } else if (name === "salaryRange") {
         // @ts-ignore
         if (event?.target.value[1] === RANGE_SALARY) {
            setUpdateFilter({
               key: name,
               value: {
                  min: event?.target.value[0],
                  max: ""
               }
            })
            setRangeSalary(event?.target.value)
            return;
         }
         setUpdateFilter({
            key: name,
            value: {
               min: event?.target.value[0],
               max: event?.target.value[1]
            }
         })
         setRangeSalary(event?.target.value)
      } else {
         setUpdateFilter({
            key: name,
            value: event?.target.value,
         })
      }
   }

   const filterBySkill = (e: any) => {
      setTimeout(() => {
         setSkill(e.target.value);
      }, 500)
   }

   const addSkill = (skill: string) => {
      setSelectedSkill(skill);
   }

   const onRemoveSkillOnFilter = (index: number) => {
      removeSkill(index)
   }

   const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
         event.key === 'Backspace' &&
         event.currentTarget.value.length === 1 ||
         event.key === 'Backspace' &&
         event.currentTarget.value === ""
      ) {
         setSkill('null');
         setTimeout(() => {
            setSkill('null');
         }, 300)
      }
   }

   const onHandleResetFilters = () => {
      resetFilters()
      setRangeYears([0, 15])
      setRangeSalary([0, 150]);
   }

   return {
      candidates,
      // Fetch skills
      skills,
      isFetchingSkills,
      isErrorFilterData,
      //Candidates by skilll
      page,
      rangeSalary,
      rangeYears,
      // state filter
      isFetchingFilterData,
      filterData,
      filters,
      setPage,
      setRangeYears,
      setRangeSalary,
      addSkill,
      filterBySkill,
      onRemoveSkillOnFilter,
      handleKeyDown,
      updateFilter,
      resetFilters,
      onHandleResetFilters
   }
}
export default useBoardController;

import Box from "@mui/material/Box";
import useStyles from "./useStyles";
import {Search} from "../../components/ui/Search";
import CustomTypography from "../../components/ui/Typography/Typography";
import {Chip} from "../../components/ui/Chip";
import {Select} from "../../components/ui/Select";
import {Slider} from "../../components/ui/Slider";
// import {CheckBox} from "../../components/ui/CheckBox";
// import iconAI from "../../../assets/AI Checked.svg";
import useBoardController from "../../../view-controllers/useBoardController";
import ListSkill from "./ListSkill";
import {INDUSTRIES, REGIONS, SENIORITY, TYPE_POSITIONS, TYPES_FILTER} from "../../../data/constant/constants";
import {COUNTRIES} from "../../../data/constant/countries";
import {CustomButton} from "../../components/ui/Button";

const Filters = () => {

   const {
      handleKeyDown,
      onRemoveSkillOnFilter,
      skills,
      isFetchingSkills,
      filters,
      filterBySkill,
      addSkill,
      rangeYears,
      rangeSalary,
      setRangeSalary,
      setRangeYears,
      updateFilter,
      onHandleResetFilters
   } = useBoardController();

   const {classesUseStyles} = useStyles();

   return (
      <Box sx={classesUseStyles.filtersContainer}>
         <Box p={4}>
            <CustomTypography
               title="Filters"
               fontWeight="bold"
               fontSize={24}
               sx={{
                  mt: 2,
                  mb: 1
               }}
            />
            <Box sx={{
               height: '100%',
               mt: 3
            }}>
               <Box>
                  <CustomTypography
                     fontWeight="bold"
                     title="Skill"
                     fontSize={12}
                     sx={{mb: 1}}
                  />
                  <Search
                     styles={classesUseStyles.searchInput}
                     onChange={filterBySkill}
                     onKeyDown={handleKeyDown}
                  />
                  <ListSkill
                     onClick={addSkill}
                     skills={skills?.data}
                     isFetching={isFetchingSkills}
                  />
                  <Box mt={1}>
                     {filters?.skill?.map((skill, index) => (
                        <>
                           {skill !== "" ?
                              <Chip
                                 index={index}
                                 label={skill}
                                 onDelete={onRemoveSkillOnFilter}
                                 sx={{
                                    backgroundColor: '#9747FF52',
                                    marginBottom: 1,
                                    mr: 1
                                 }}
                              />
                              :
                              <></>
                           }
                        </>
                     ))}
                  </Box>
               </Box>

               <Box mt={4}>
                  <CustomTypography
                     title="Type"
                     fontSize={12}
                     fontWeight="bold"
                     sx={{mb: 1}}
                  />
                  <Select
                     name="type"
                     data={TYPE_POSITIONS}
                     selected={filters?.type}
                     title={filters?.type ? filters?.type : 'All'}
                     defaultValue={!filters?.type ? "" : filters?.type}
                     onChange={updateFilter}
                     styles={classesUseStyles.inputFilter}
                     styleSelect={classesUseStyles.selectContainer}
                  />
               </Box>

               <Box mt={4}>
                  <CustomTypography
                     fontWeight="bold"
                     title="Region"
                     fontSize={12}
                     sx={{mb: 1}}
                  />
                  <Select
                     name='region'
                     data={REGIONS}
                     selected={filters?.region}
                     title={
                        filters?.region !== "" ? filters?.region : 'All'
                     }
                     defaultValue={!filters?.region ? "" : filters?.region }
                     styles={classesUseStyles.inputFilter}
                     styleSelect={classesUseStyles.selectContainer}
                     onChange={updateFilter}
                  />
               </Box>

               <Box mt={4}>
                  <CustomTypography
                     title="Country"
                     fontSize={12}
                     fontWeight="bold"
                     sx={{mb: 1}}
                  />
                  <Select
                     data={COUNTRIES}
                     name="country"
                     selected={filters?.country}
                     title={
                        !filters?.country  ? "All" : filters?.country
                     }
                     defaultValue={!filters?.country ? "" : filters?.country}
                     onChange={updateFilter}
                     styles={classesUseStyles.inputFilter}
                     styleSelect={classesUseStyles.selectContainer}
                  />
               </Box>

               <Box mt={4}>
                  <CustomTypography
                     fontWeight="bold"
                     title="Years Of Experience"
                     fontSize={12}
                     sx={{mb: 1}}
                  />
                  <Slider
                     name="yearsOfExperience"
                     range={rangeYears}
                     setRange={setRangeYears}
                     handleChange={updateFilter}
                     minValue={0}
                     maxValue={15}
                  />
               </Box>

               <Box mt={4}>
                  <CustomTypography
                     fontWeight="bold"
                     title="Seniority"
                     fontSize={12}
                     sx={{mb: 1}}
                  />
                  <Select
                     name="seniority"
                     data={SENIORITY}
                     selected={filters?.seniority}
                     title={
                        !filters?.seniority  ? "All" : filters?.seniority
                     }
                     defaultValue={!filters?.seniority ? "" : filters?.seniority}
                     onChange={updateFilter}
                     styles={classesUseStyles.inputFilter}
                     styleSelect={classesUseStyles.selectContainer}
                  />
               </Box>

               <Box mt={4}>
                  <CustomTypography
                     fontWeight="bold"
                     title="Salary Range"
                     fontSize={12}
                     sx={{mb: 1}}
                  />
                  <Slider
                     name="salaryRange"
                     range={rangeSalary}
                     setRange={setRangeSalary}
                     handleChange={updateFilter}
                     minValue={0}
                     maxValue={150}
                  />
               </Box>

               {/*<Box mt={3}>*/}
               {/*   <CustomTypography*/}
               {/*      fontWeight="bold"*/}
               {/*      title="Bull Score"*/}
               {/*      fontSize={12}*/}
               {/*   />*/}
               {/*   <Select*/}
               {/*      value="select"*/}
               {/*      defaultValue="Select"*/}
               {/*      title="Select"*/}
               {/*      styles={classesUseStyles.inputFilter}*/}
               {/*      styleSelect={classesUseStyles.selectContainer}*/}
               {/*   />*/}
               {/*</Box>*/}

               <Box mt={4}>
                  <CustomTypography
                     fontWeight="bold"
                     title="Industry"
                     fontSize={12}
                     sx={{mb: 1}}
                  />
                  <Select
                     name="industries"
                     data={INDUSTRIES}
                     onChange={updateFilter}
                     selected={filters?.industries}
                     title={
                        !filters?.industries  ? "All" : filters?.industries
                     }
                     defaultValue={!filters?.industries ? "" : filters?.industries}
                     styles={classesUseStyles.inputFilter}
                     styleSelect={classesUseStyles.selectContainer}
                  />
               </Box>

               <Box mt={4}>
                  <CustomButton
                     title="Reset filters"
                     variant="outlined"
                     fullWidth={true}
                     onPress={() => onHandleResetFilters()}
                  />
               </Box>

               {/*<Box mt={3}>*/}
               {/*   <CustomTypography*/}
               {/*      fontWeight="bold"*/}
               {/*      title="My lists"*/}
               {/*      fontSize={12}*/}
               {/*   />*/}
               {/*   <Select*/}
               {/*      value="select"*/}
               {/*      defaultValue="Select"*/}
               {/*      title="Select"*/}
               {/*      styles={classesUseStyles.inputFilter}*/}
               {/*      styleSelect={classesUseStyles.selectContainer}*/}
               {/*   />*/}
               {/*</Box>*/}

               {/*<Box mt={4}>*/}
               {/*   <CustomTypography*/}
               {/*      fontWeight="bold"*/}
               {/*      title="Tested Only"*/}
               {/*      fontSize={12}*/}
               {/*   />*/}
               {/*   <Box*/}
               {/*      display="flex"*/}
               {/*      alignItems="center"*/}
               {/*      ml={-1.5}*/}
               {/*   >*/}
               {/*      <CheckBox*/}
               {/*      />*/}
               {/*      <Box display='flex' mt={0.5}>*/}
               {/*         <img*/}
               {/*            src={iconAI}*/}
               {/*         />*/}
               {/*         <CustomTypography*/}
               {/*            title="Tested Only"*/}
               {/*            fontSize={13}*/}
               {/*         />*/}
               {/*      </Box>*/}
               {/*   </Box>*/}
               {/*</Box>*/}
            </Box>
         </Box>
      </Box>
   )
}
export default Filters;

import {useDispatch, useSelector} from "react-redux";
import {useFetchSkillsQuery} from "../store/app/apiSlice/apiSlice";
import {StoreCandidateType} from "../types/stateTypes";
import {
   setCandidates,
   setSkill,
} from "../store/features/candidate-slice";

const useCandidatesViewModel = () => {
   const dispatch = useDispatch();

   const {
      candidates,
      skill,
   } = useSelector((state: StoreCandidateType) => state.CandidateReducer);

   const {
      data: skills,
      isFetching: isFetchingSkills,
      isSuccess: isSuccessFetchSkills,
      isError: isErrorFetchSkills,
   } = useFetchSkillsQuery({
      skill: skill
   }, {refetchOnMountOrArgChange: true });

   return {
      //Fetch all candidates
      candidates,
      //Fetch skills
      skills,
      isFetchingSkills,
      isSuccessFetchSkills,
      isErrorFetchSkills,
      //fn
      setCandidates: (candidates: any) => dispatch(setCandidates(candidates)),
      setSkill: (skill: string) => dispatch(setSkill(skill)),
   }

}
export default useCandidatesViewModel;

export const MSG_ERROR = [
   'Required',
   'Please enter a valid email address',
   'The passwords do not match',
   'Reset password token has expired'
];

export const REGIONS = [
   {label: 'All', value: ""},
   {value: 'LATAM', label: 'LATAM'},
   {value: 'ASIA', label: 'ASIA'},
   {value: 'EUROPE', label: 'EUROPE'},
   {value: 'NORTH AMERICA', label: 'NORTH AMERICA'},
   {value: 'AFRICA', label: 'AFRICA'},
   {value: 'OCEANIA', label: 'OCEANIA'},
   {value: 'ANTARCTICA', label: 'ANTARCTICA'},
];

export const TYPES_FILTER = [
   {value: 'Front-end Developer', label: 'Front-end Developer'}
];
export const INDUSTRIES = [
   {label: 'All', value: ""},
   {value: 'Medical', label: 'Medical'},
   {value: 'Others', label: 'Others'}
]

export const SENIORITY = [
   {label: 'All', value: ""},
   {label: 'Junior', value: 'Junior'},
   {label: 'Mid', value: 'Mid'},
   {label: 'Senior', value: 'Senior'}
];

export const TYPE_POSITIONS = [
   {label: 'All', value: ""},
   {label: 'Backend Developer', value: 'Backend Developer'},
   {label: 'Frontend Developer', value: 'Frontend Developer'},
   {label: 'Full Stack Developer', value: 'Full Stack Developer'},
   {label: 'DevOps Engineer', value: 'DevOps Engineer'},
   {label: 'QA Engineer', value: 'QA Engineer'},
   {label: 'UI/UX Designer', value: 'UI/UX Designer'},
   {label: 'Product Manager', value: 'Product Manager'},
   {label: 'Scrum Master', value: 'Scrum Master'},
   {label: 'Software Architect', value: 'Software Architect'},
   {label: 'Database Administrator', value: 'Database Administrator'},
]

export const RANGE_YEARS_EXPERIENCE = 15;
export const RANGE_SALARY = 150;



const useStyles = () => {
   const classesUseStyles = {
      sidebar: {
         width: 87,
         bgcolor: 'primary.main',
         height: "100%"
      },
      logoImg: {
         width: '100%',
         height: 127
      }
   }

   return {classesUseStyles};
}
export default useStyles;

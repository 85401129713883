import {useDispatch, useSelector} from "react-redux";
import {StoreType} from "../types/filterTypes";
import {setUpdateFilter, setSkill, removeSkill, resetFilters} from "../store/features/filter-slice";
import {useFetchCandidatesQuery} from "../store/app/apiSlice/apiSlice";
import {useState} from "react";
import {StoreCandidateType} from "../types/stateTypes";
import {setPage} from "../store/features/candidate-slice";

const useBoardViewModel = () => {
   const [limit, setLimit] = useState<number>(10);

   const dispatch = useDispatch();

   const {page} = useSelector((state: StoreCandidateType) => state.CandidateReducer)
   const filters = useSelector((state: StoreType) => state.FilterReducer);

   const {
      data: filterData,
      isFetching: isFetchingFilterData,
      isSuccess: isSuccessFilterData,
      isError: isErrorFilterData,
      refetch: refetchFilterData
   } = useFetchCandidatesQuery(
      {
      page: page,
      limit: limit,
      searchCriteria: JSON.stringify(filters)
      },
      {
         refetchOnMountOrArgChange: true,
      }
   );

   return {
      page,
      filters,
      filterData,
      isFetchingFilterData,
      isSuccessFilterData,
      isErrorFilterData,
      refetchFilterData,
      setUpdateFilter: (filter: object) => dispatch(setUpdateFilter(filter)),
      setSkill: (skill: string) => dispatch(setSkill(skill)),
      removeSkill: (index: number) => dispatch(removeSkill(index)),
      setPage: (page: number) => dispatch(setPage(page)),
      resetFilters: () => dispatch(resetFilters())
   }
}
export default useBoardViewModel;

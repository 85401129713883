import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import iconAI from '../../../assets/AI Checked.svg';
import iconLock from '../../../assets/Lock.svg';
import iconEdit from '../../../assets/Mode edit.svg';
import Box from "@mui/material/Box";
import CustomTypography from "../../components/ui/Typography/Typography";
import {CircularProgress} from "../../components/ui/CircularProgress";

const CutomTable = ({candidates, isFetchingCandidates, isError}: any) => {
   return (
      <TableContainer component={Paper}>

         {!isFetchingCandidates ?

            <>
               {!isError ?
                  <Table sx={{width: '100%', height: '100%'}} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell align="left">Name</TableCell>
                           <TableCell align="left">Region/Country</TableCell>
                           <TableCell align="center">Experience</TableCell>
                           <TableCell align="center">Prof.Skill</TableCell>
                           <TableCell align="left">Rate</TableCell>
                           <TableCell align="left">Industries</TableCell>
                           <TableCell align="left">View Profile</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        <>
                           {candidates?.map((row: any, index: number) => (
                              <TableRow
                                 key={index}
                                 sx={{'&:last-child td, &:last-child th': {border: 0}}}
                              >
                                 <TableCell component="th" scope="row">
                                    <Box display="flex">
                                       <img
                                          src={iconAI}
                                          style={{marginRight: 5}}
                                       />
                                       <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                                          {
                                             row.name === 'Hidden'
                                                ? <img src={iconLock}/>
                                                : <CustomTypography title={row.name} fontSize={12}/>
                                          }
                                       </Box>
                                    </Box>
                                 </TableCell>
                                 <TableCell align="center">{row.country}</TableCell>
                                 <TableCell align="center">
                                    {row.experience === 'Hidden' ? <img src={iconLock}/> : row.experience}
                                 </TableCell>
                                 <TableCell align="center">{row.professionalSkill}</TableCell>
                                 <TableCell align="left">{row.rate}</TableCell>
                                 <TableCell align="left">{row.industries}</TableCell>
                                 <TableCell align="left">
                                    <Box display="flex" alignItems="center">
                                       <img
                                          src={iconEdit}
                                          style={{marginRight: 3}}
                                       />
                                       <CustomTypography
                                          title="1 Credit"
                                          fontSize={12}
                                       />
                                    </Box>
                                 </TableCell>
                              </TableRow>
                           ))}
                        </>
                     </TableBody>
                  </Table>

                  :

                  <Box
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     width="100%"
                     py={2}
                  >
                     <CustomTypography
                        fontWeight="bold"
                        title="No results match your search"
                     />
                  </Box>

               }
            </>

            :

            <Box
               display="flex"
               justifyContent="center"
               alignItems="center"
               width="100%"
               py={2}
            >
               <CircularProgress />
            </Box>

         }
      </TableContainer>
   )
}
export default CutomTable;

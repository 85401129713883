import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CandidateStateType} from "../../types/stateTypes";

const initialState: CandidateStateType = {
   candidates: [],
   skill: '',
   page: 1
}

const candidateSlice = createSlice({
   name: 'candidateSlice',
   initialState,
   reducers: {
      setCandidates: (state, action) => {
         state.candidates = action.payload;
      },
      setSkill: (state, action) => {
         state.skill = action.payload;
      },
      setPage: (state, action) => {
         state.page = action.payload;
      },
   }
})
export const {
   setCandidates,
   setSkill,
   setPage,
} = candidateSlice.actions;
export default candidateSlice.reducer;

import Box from "@mui/material/Box";
import CustomTypography from "../../components/ui/Typography/Typography";
import {CustomInput} from "../../components/ui/Input";
import useAuthController from "../../../view-controllers/useAuthController";
import {CustomButton} from "../../components/ui/Button";
import {Container} from "@mui/material";
import useStyles from "./useStyles";

const Login = () => {

   const {classes} = useStyles();
   const {register, handleSubmit, auth} = useAuthController();

   return (
      <Container
         maxWidth="lg"
         sx={classes.flexCenter}
      >
         <Box sx={[classes.container, classes.flexCenter]}>
            <Box sx={classes.box}></Box>
            <Box sx={classes.boxFormContainer}>
               <form onSubmit={handleSubmit(data => auth({email: data.email, password: data.password}))}>
                  <Box sx={classes.boxForm}>
                     <CustomTypography
                        title="LOG IN"
                        fontWeight={700}
                        fontSize={32}
                     />
                     {/*<CustomAlert />*/}
                     <Box sx={classes.inputBox}>
                        <Box>
                           <CustomTypography
                              title="Email"
                              sx={classes.label}
                           />
                           <CustomInput
                              fullWidth={true}
                              value="info+system@softonitg.com"
                              placeholder="Email:"
                              {...register('email')}
                           />
                           {/*<ErrorLabel*/}
                           {/*   error={errors?.email}*/}
                           {/*   message={errors?.email?.message}*/}
                           {/*/>*/}
                        </Box>
                        <Box my={4}>
                           <CustomTypography
                              title="Password"
                              sx={classes.label}
                           />
                           <CustomInput
                              fullWidth={true}
                              value="administrador1234!"
                              placeholder="Password:"
                              {...register("password")}
                           />
                           {/*<InputIcon*/}
                           {/*   placeholder="Your Password"*/}
                           {/*   {...register("password")}*/}
                           {/*/>*/}
                           {/*<ErrorLabel*/}
                           {/*   error={errors?.password}*/}
                           {/*   message={errors?.password?.message}*/}
                           {/*/>*/}
                        </Box>
                        <CustomButton
                           title="LOG IN"
                           variant="contained"
                           size="large"
                           type="submit"
                           sx={classes.button}
                        />
                        {/*<CustomTypography*/}
                        {/*   title="Forgot password?"*/}
                        {/*   fontWeight={400}*/}
                        {/*   fontSize={14}*/}
                        {/*   color={"primary.main"}*/}
                        {/*   sx={classes.forgotPass}*/}
                        {/*   onClick={() => navigate(ROUTE_ENDPOINTS.FORGOT_PASSWORD)}*/}
                        {/*/>*/}
                     </Box>
                  </Box>
               </form>
            </Box>
         </Box>
      </Container>
   )
}
export default Login;

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {intersection} from "lodash";
import {logout, setCredentials} from "../../features/auth-slice";

export const baseQuery = fetchBaseQuery({
   // baseUrl: 'http://localhost:80/api/v1',
   baseUrl: 'https://bullhire-api.softonitg.com/api/v1',
   prepareHeaders: (headers: any, {getState}: any) => {
      const token = getState().AuthReducer?.token;
      if (token) headers.set('authorization', `Bearer ${token}`)
      headers.set(['userSignature'], 'f0e00f86f5dfbbc90cf36b8f3b7ed272f3aa76dc3eaa9bcc24114e3c39457495');
      return headers
   }
});

export const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
   let result = await baseQuery(args, api, extraOptions);
   // @ts-ignore
   if (intersection([result?.error?.originalStatus, result?.error?.status], [401, 410]).length > 0) {
      api.dispatch(logout());
   } else if (result?.meta?.response?.headers?.get('token')) {
      api.dispatch(setCredentials({ data: { token: result?.meta?.response?.headers?.get('token')}}))
   }
   return result;
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import CustomTypography from "../Typography/Typography";

function valuetext(value: number) {
   return `${value}°C`;
}

const RangeSlider = ({range, handleChange, name, maxValue, minValue}: any) => {
   return (
      <Box sx={{width: '100%'}}>
         <Box
            display="flex"
            justifyContent="space-between"
         >
            <Box
               display="flex"
            >
               {
                  name === 'salaryRange' && '$'
               }
               <CustomTypography
                  title={range[0]}
                  fontSize={12}
                  fontWeight="bold"
               />
            </Box>
            <Box
               display="flex"
               alignItems="center"
            >
               {
                  name === 'salaryRange' && '$'
               }
               <CustomTypography
                  title={range[1] + ' + '}
                  fontSize={12}
                  fontWeight="bold"
               />
            </Box>
         </Box>
         <Slider
            size="small"
            value={range}
            onChange={(event) => handleChange(name, event)}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            max={maxValue}
            min={minValue}
         />
      </Box>
   );
}

export default RangeSlider;

import {FilterType} from "../../types/filterTypes";
import {createSlice} from "@reduxjs/toolkit";

const initialState: FilterType = {
   region: '',
   type: '',
   country: '',
   seniority: '',
   industries: '',
   skill: [""],
   yearsOfExperience: {min: 0,max: ''},
   salaryRange: {min: 0,max: ''}
}

const filterSlice = createSlice({
   name: 'filterSlice',
   initialState,
   reducers: {
      setUpdateFilter: (state: any, action) => {
         const {key, value} = action.payload;
         if (value === 'All') {
            state[key] = "";
            return;
         }
         state[key] = value;
      },
      setSkill: (state, action) => {
         console.log("state action", action.payload);
         if (action.payload) {
            if (state.skill.length > 0 && state.skill[0] === "") {
               state.skill.shift();
            }
            if (!state.skill.includes(action.payload)) {
               state.skill.push(action.payload);
            }
         }
      },
      removeSkill: (state, action) => {
         console.log("index", action.payload);
         state.skill.splice(action.payload, 1);
         if (state.skill.length === 0) {
            state.skill = initialState.skill
         }
      },
      resetFilters: (state) => {
         Object.assign(state, initialState);
      }
   }
})
export const {
   setUpdateFilter,
   setSkill,
   removeSkill,
   resetFilters
} = filterSlice.actions;
export default filterSlice.reducer;

import * as React from 'react';
import Box from '@mui/material/Box';
import useStyles from "./useStyles";
import logoBullHire from '../../../../assets/Group 15.svg';

const Sidebar = () => {

   // @ts-ignore
   const { classesUseStyles } = useStyles();

   return (
      <Box sx={classesUseStyles.sidebar}>
         <Box width="100%">
            <img
               style={classesUseStyles.logoImg}
               src={logoBullHire}
               alt="bullHire-logo"
            />
         </Box>
      </Box>
   )
}
export default Sidebar;

import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const CustomPagination = ({ setPage, page, totalPages }: any) => {

   const handleChange = (event: any, value: any) => {
      console.log("value", value);
      setPage(value);
   }

   return (
      <Stack spacing={2}>
         <Pagination  count={totalPages} color="primary" onChange={handleChange} page={page} />
      </Stack>
   )
}
export default CustomPagination;

import {Routes, Route, BrowserRouter, Navigate} from "react-router-dom";
import {ROUTE_CONSTANTS} from "../../data/constant/RouteConstants";
import {Board} from "../pages/Board";
import {Login} from "../pages/Login";
import useAuthViewModel from "../../view-models/useAuthViewModel";

const AppNavigation = () => {

   const {token} = useAuthViewModel();

   return (
      <BrowserRouter>
         <Routes>
            {
               !token ? (
                  <Route path={ROUTE_CONSTANTS.Login} element={<Login/>}/>
               ) : (
                  <>
                     <Route path="/" element={<Navigate to={ROUTE_CONSTANTS.BOARD}/>}/>
                     <Route path={ROUTE_CONSTANTS.BOARD} element={<Board/>}/>
                  </>
               )
            }
         </Routes>
      </BrowserRouter>
   )
}
export default AppNavigation;

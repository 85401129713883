import {Sidebar} from "../../components/layout/Sidebar";
import CustomTable from "./CutomTable";
import Box from "@mui/material/Box";
import {Container} from "@mui/material";
import useStyles from "./useStyles";
import {Typography} from "../../components/ui/Typography";
import useBoardController from "../../../view-controllers/useBoardController";
import Filters from "./Filters";
import {Header} from "../../components/layout/Header";
import {Pagination} from "../../components/layout/Pagination";

const Board = () => {

   const {
      candidates,
      page,
      isFetchingFilterData,
      isErrorFilterData,
      setPage,
   } = useBoardController();

   const {classesUseStyles} = useStyles();

   return (
      <Container maxWidth='xl' sx={classesUseStyles.container}>
         <Box sx={classesUseStyles.sidebar}>
            <Sidebar/>
         </Box>
         <Box height="100%">
            <Filters/>
         </Box>
         <Box sx={classesUseStyles.customTable}>
            <Box p={2}>
               <Header/>
               <Typography
                  title="Candidates"
                  fontWeight="bold"
                  fontSize={24}
                  sx={{
                     mb: 2
                  }}
               />
            </Box>
            <Box
               p={2}
               display="flex"
               flexDirection='column'
               justifyContent='center'
               alignItems='center'
            >
               <CustomTable
                  isError={isErrorFilterData}
                  isFetchingCandidates={isFetchingFilterData}
                  candidates={candidates?.result?.data}
               />
               <Box mt={2}>
                  <Pagination
                     page={page}
                     setPage={setPage}
                     totalPages={candidates?.result?.totalPages}
                  />
               </Box>
            </Box>
         </Box>
      </Container>
   )
}
export default Board;

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

const Search = ({onChange, styles, onKeyDown}: any) => {
   return (
      <Paper
         elevation={0}
         component="form"
         sx={styles}
      >
         <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
         </IconButton>
         <InputBase
            onChange={(e) => onChange(e)}
            sx={{ flex: 1 }}
            placeholder="Skill"
            inputProps={{ 'aria-label': 'Skill' }}
            onKeyDown={onKeyDown}
         />
      </Paper>
   )
}
export default Search;

import Box from "@mui/material/Box";
import {ListCandidate} from "../../../types/candidateTypes";
import {ListItem, ListItemButton, ListItemText} from "@mui/material";
import {CircularProgress} from "../../components/ui/CircularProgress";

const ListSkill = ({skills, isFetching, onClick}: ListCandidate) => {

   return (
      <Box>
         {isFetching ?
            <Box display="flex" justifyContent="center" p={2}>
               <CircularProgress size={20}/>
            </Box>
            :
            <>
               {skills?.length > 0 ? (
                  <ul
                     style={{
                     maxHeight: '150px',
                     overflowY: 'auto',
                     border: '1px solid #ccc',
                     padding: '0',
                     margin: '0'
                  }}
                  >
                     {skills?.map((result: any, index: any) => (
                        <ListItem key={result?.id} component="div" disablePadding disableGutters>
                           <ListItemButton key={result?.id} onClick={() => onClick(result?.name)}
                                           alignItems="center">
                              <ListItemText key={result?.id} primary={`${result?.name}`}/>
                           </ListItemButton>
                        </ListItem>
                     ))}
                  </ul>
               ) : <></>}
            </>
         }
      </Box>
   )
}
export default ListSkill;

const useStyles = () => {
   const classes = {
      container: {
         width: '1108px',
         flex: 1
      },
      boxForm: {
         flex: 2
      },
      inputBox: {
         display: 'flex',
         flexDirection: 'column',
         width: '500px',
         mt: 4
      },
      box: {
         p: 20,
         height: 554,
         width: 410,
         backgroundColor: '#BC88FF',
         borderRadius: '20px',
         opacity: "12%",
         flex: 2
      },
      boxFormContainer: {
         p: 10,
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center'
      },
      label: {
         color: '#000',
         mb: 1,
         fontWeight: '700'
      },
      button: {
         borderRadius: 20,
         mb: 5,
         fontWeight: 'bold'
      },
      forgotPass: {
         cursor: 'pointer'
      },
      flexCenter: {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         height: '95vh',
      },
      flexAlignCenter: {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
      }
   }
   return {
      classes
   }
}
export default useStyles;

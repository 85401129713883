import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   user: null,
   token: null
}

const authSlice = createSlice({
   name: 'authSlice',
   initialState,
   reducers: {
      setCredentials: (state, action) => {
         if (action.payload.data?.user) state.user = action.payload.data?.user;
         if (action.payload.data?.token) state.token = action.payload.data?.token;
      },
      logout: (state) => {
         state.user = null;
         state.token = null;
      }
   }
})
export const {setCredentials, logout} = authSlice.actions;
export default authSlice.reducer;

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {MSG_ERROR} from "../data/constant/constants";
import useAuthViewModel from "../view-models/useAuthViewModel";
import {useEffect} from "react";

const useAuthController = () => {
   const navigate = useNavigate();

   const {auth, isSuccess, data, setCredentials}: any = useAuthViewModel();

   const shema = yup
      .object().shape({
         email: yup.string().required(MSG_ERROR[0]).email(MSG_ERROR[1]),
         password: yup.string().required(MSG_ERROR[0]),
      })

   const {
      register,
      handleSubmit,
      formState: {errors}
   } = useForm({
      resolver: yupResolver(shema),
      mode: 'onChange'
   });

   useEffect(() => {
      if (isSuccess) {
         setCredentials(data)
      }
   }, [isSuccess]);

   const submit = (data: any) => {
     auth(data);
   }

   return {
      auth: submit,
      errors,
      register,
      handleSubmit,
      navigate
   }

}
export default useAuthController;

import {Button} from "@mui/material";
import {useCallback} from "react";
import Stack from '@mui/material/Stack';
// import Loading from "../Loading";

const CustomButton = ({
                         variant,
                         title,
                         isDisabled,
                         onPress,
                         size,
                         style,
                         type,
                         color,
                         fullWidth,
                         sx,
                         isLoading
                      }: any) => {

   const onHandlePress = useCallback(() => {
      onPress();
   }, [onPress]);

   return (
      <Stack direction="row" spacing={0}>
         <Button
            sx={sx}
            variant={variant}
            disabled={isLoading || isDisabled}
            onClick={onPress && onHandlePress}
            size={size}
            style={style}
            type={type}
            color={color}
            fullWidth={fullWidth}
            // startIcon={
            //    isLoading &&
            //    <Loading
            //       size={20}
            //       style={{color: 'primary.main'}}
            //    />
            // }
         >
            {title}
         </Button>
      </Stack>
   )
}
export default CustomButton;

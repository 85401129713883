import Box from "@mui/material/Box";
import CustomTypography from "../../ui/Typography/Typography";
import {CustomButton} from "../../ui/Button";

const Header = () => {
   return (
      <Box
         width="100%"
         height={100}
         p={1}
         display="flex"
         alignItems="center"
      >
         <Box display="flex" width="80%" alignItems="center" justifyContent="space-between">
            <CustomTypography
               title="20 Credits"
            />
            <CustomButton
               title="UPGRADE PLAN"
               variant="contained"
               size="small"
               style={{borderRadius: 50}}
            />
            <CustomButton
               title="BOOK A DEMO"
               variant="contained"
               size="small"
               style={{
                  borderRadius: 50,
                  backgroundColor: 'rgba(188, 136, 255, 1)'
               }}
            />
            <CustomButton
               title="GET FREE CREDITS"
               variant="contained"
               size="small"
               style={{
                  borderRadius: 50,
                  backgroundColor: 'rgba(252, 226, 60, 1)',
                  color: '#000'
               }}
            />
         </Box>
      </Box>
   )
}
export default Header;

import React, {forwardRef} from "react";
import {
   FormControl,
   Select
} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const CustomSelect = forwardRef(({
                                    name,
                                    onChange,
                                    styles,
                                    styleSelect,
                                    borderRadius,
                                    size,
                                    placeholder,
                                    data,
                                    title,
                                    defaultValue,
                                    selected,
                                    icon,
                                    value,
                                    disabled,
                                    ...props
                                 }: any, ref) => {


   const handleOnChange = (event: any) => onChange(name, event);

   return (
      <FormControl sx={styles}>
         <Select
            name={name}
            defaultValue={defaultValue}
            value={value === 'all' ? "" : value}
            onChange={handleOnChange}
            sx={styleSelect}
            size={size}
            placeholder={placeholder}
            disabled={disabled}
            native
            {...props}
            ref={ref}
            renderValue={(selected: any) => {
               if (selected.length === 0) {
                  return <em>{title}</em>;
               }
               return selected.join(', ');
            }}
            startAdornment={icon && <AccountCircleIcon sx={{mr: 1}}/>}
         >
            <>
               <option selected={selected} disabled value={defaultValue}>
                  <em>{title}</em>
               </option>
               {data &&
                  data.map((item: any, index: any) => (
                     <option
                        key={index}
                     >
                        {item?.label}
                     </option>
                  ))}
            </>
         </Select>
      </FormControl>
   )
})
export default CustomSelect;

import {useDispatch, useSelector} from "react-redux";
import {useAuthMutation} from "../store/app/apiSlice/apiSlice";
import {setCredentials} from "../store/features/auth-slice";

const useAuthViewModel = () => {
   const dispatch = useDispatch();

   const {user, token}: any = useSelector<any>(state => state.AuthReducer);

   const [auth, {data, isSuccess, isError, error: authError}] = useAuthMutation();

   return {
      //state
      user,
      token,
      //auth
      auth,
      data,
      isSuccess,
      //fn
      setCredentials: (credentials: any) => dispatch(setCredentials(credentials))
   }
}
export default useAuthViewModel;
